<template>
  <div
    v-if="song"
    class="page-with-title"
  >
    <page-title>
      <h1>Correção: {{ song.song.titulo }}</h1>
      <a
        :href="song.song.url"
        target="_blank"
      >{{ song.song.url }}</a>
    </page-title>
    <div class="p-4">
      <div class="row">
        <div class="col-md-1">
          <label>ID</label>
          <input
            :value="song.song.id_musica"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="col-md-3">
          <label>Titulo</label>
          <input
            :value="song.song.titulo"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-3">
          <label>Artista</label>
          <input
            :value="song.song.artista"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-1">
          <label>Ano</label>
          <input
            :value="song.song.ano"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-2">
          <label>Cód. Vídeo</label>
          <input
            :value="song.song.cod_video"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-1">
          <label>Rank</label>
          <input
            :value="song.song.rank"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-3 mt-4">
          <label>Usuário</label>
          <input
            :value="username"
            type="text"
            class="form-control"
            readonly
          >
        </div>
        <div class="col-md-6 mt-4">
          <label>Detalhes</label>
          <textarea
            :value="song.detalhes"
            class="form-control"
            rows="3"
            readonly
          />
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-3">
            Correção
          </h4>
          <div class="form-group">
            <input
              v-model="song.titulo_correcao"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <textarea
              ref="textarea2"
              v-model="song.letra_correcao"
              class="form-control"
              rows="22"
              @scroll="$refs.textarea1.scrollTop = $refs.textarea2.scrollTop"
            ></textarea>
          </div>
          <div>
            <label>Compositores</label>
            <input
              v-model="song.compositores"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <h4 class="mb-3">Original</h4>
          </div>
          <div class="form-group">
            <input
              :value="song.song.titulo"
              type="text"
              class="form-control"
              readonly
            >
          </div>
          <div class="form-group">
            <textarea
              v-if="song.lyrics"
              v-model="song.lyrics.letra"
              ref="textarea1"
              class="form-control"
              rows="22"
              readonly
            ></textarea>
          </div>
          <div>
            <label>Compositores</label>
            <input
              :value="song.song.compositores"
              type="text"
              class="form-control"
              readonly
            >
          </div>
        </div>
      </div>
      <div class="mt-4 mb-3">
        <button
          class="btn btn-2 btn-bigger mr-3"
          @click="approve"
        >
          <b><i class="fa fa-check" /> Aprovar correção</b>
        </button>
        <button
          class="btn btn-link text-danger"
          @click="disapprove"
        >
          <b><i class="fa fa-close" /> Recusar correção</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import {
    getSongPendingCorrection,
    updateSongPendingCorrectionStatus
  } from '@/api/songs'

  export default {
    components: { PageTitle },
    data: () => ({
      song: null
    }),
    mounted() {
      this.getSongPendingCorrection()
    },
    watch: {
      '$route'() {
        this.getSongPendingCorrection()
      }
    },
    computed: {
      username() {
        if (!this.song.member) {
          return 'Usuário não identificado'
        }
        return `${this.song.member.nome} ${this.song.member.sobrenome} (ID: ${this.song.member.id_membro})`
      }
    },
    methods: {
      async getSongPendingCorrection() {
        const res = await getSongPendingCorrection(this.$route.params.id)
        this.song = res.data
      },
      approve() {
        this.updateStatus(this.$route.params.id, 'approved')
      },
      disapprove() {
        this.updateStatus(this.$route.params.id, 'disapproved')
      },
      async updateStatus(id, status) {
        const res = await updateSongPendingCorrectionStatus(id, status, {
          new_title: this.song.titulo_correcao,
          new_lyrics: this.song.letra_correcao,
          composers: this.song.compositores
        })

        if (res.data.next_correction_id) {
          this.$router.push({
              name: 'songCorrectionShow', 
              params: { id: res.data.next_correction_id }
          })
        } else {
          this.$router.push({ name: 'songsCorrection' })
        }
      }
    }
  }
</script>
